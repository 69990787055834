import React from "react";
//
import { FontAwesomeIcon as FAIcon } from "@fortawesome/react-fontawesome";
import { faSearch as fasSearch, faRight as fasRight } from "@fortawesome/pro-solid-svg-icons";
//
//

import "./styles.scss";

//

class Search_Box extends React.Component {
    constructor(props) {
        super(props);
        const Z = this;

        Z.state = {
            input_text: "",
            focused: false,
        };
    }

    on_focus = () => {
        const Z = this;
        Z.setState({ focused: true });
    };
    on_blur = () => {
        const Z = this;
        Z.setState({ focused: false });
    };

    on_key_down = (event) => {
        const Z = this;
        if (event.keyCode == 13) {
            Z.do_search();
        }
    };

    do_search = () => {
        const Z = this;
        var search_url = `https://www.google.com/search?q=site%3Awchsutah.org+${encodeURIComponent(Z.state.input_text)}`;
        window.location = search_url;
        // Z.setState({ input_text: "" });
    };

    render() {
        const Z = this;

        return (
            <div className={`Search_Box ${Z.state.focused ? "_focused" : ""}`}>
                <div className="search_icon">
                    <FAIcon icon={fasSearch} />
                </div>
                <input type="text" className="search_input" value={Z.state.input_text} onChange={(e) => Z.setState({ input_text: e.target.value })} onFocus={Z.on_focus} onBlur={Z.on_blur} onKeyDown={Z.on_key_down} />
                <button onClick={Z.do_search} className="search_button">
                    <FAIcon icon={fasRight} />
                </button>
            </div>
        );
    }
}

export default Search_Box;
