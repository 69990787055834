import React, { useEffect } from "react";
// import { Helmet } from "react-helmet";
import { FontAwesomeIcon as FAIcon } from "@fortawesome/react-fontawesome";
import { faSquareFacebook as fabFacebook, faYoutube as fasYoutube } from "@fortawesome/free-brands-svg-icons";
import { faSearch as fasSearch, faCalendarDays as fasCalendarDays, faPersonDigging as fasPersonDigging } from "@fortawesome/pro-solid-svg-icons";
// import { faPaperPlane as farPaperPlane } from "@fortawesome/free-regular-svg-icons";
//
import Search_Box from "./Search_Box";
//

import "./App.scss";

//
//

const header_links = [
    {
        text: "About Us",
        dropdown: [
            { text: "About Us", url: "https://washcouthistory.org/about" },
            { text: "Contact Us", url: "https://wchsutah.org/wchs/contact-us.php" },
            { text: "Organization", url: "https://wchsutah.org/wchs/governance.php" },
        ],
    },
    {
        text: "Activities",
        dropdown: [
            { text: "Welcome", url: "https://washcouthistory.org" },
            { text: "Calendar", url: "https://washcouthistory.org/calendar" },
            { text: "County Calendar", url: "https://wchsutah.org/calendar.php" },
            { text: "Volunteering", url: "https://washcouthistory.org/get-involved" },
        ],
    },
    {
        text: "History",
        dropdown: [
            { text: "Introduction", url: "https://wchsutah.org/index-history.php" },
            { text: "Subject Categories", url: "https://wchsutah.org/categories.php" },
            { text: "Subject Index", url: "https://wchsutah.org/subject-index.php" },
            { text: "Research Tools", url: "https://wchsutah.org/references.php" },
            { text: "Genealogy", url: "https://wchsutah.org/people/genealogy.php" },
            { text: "Maps", url: "https://wchsutah.org/maps/maps.php" },
            { text: "Documents", url: "https://wchsutah.org/documents/documents.php" },
            { text: "Photos", url: "https://wchsutah.org/photos.php" },
        ],
    },
    {
        text: "Join WCHS",
        dropdown: [
            { text: "Join the WCHS", url: "https://washcouthistory.org/membership" },
            { text: "Current Members ", url: "https://wchsutah.org/wchs/members.php" },
            { text: "Life Members", url: "https://wchsutah.org/wchs/members.php#life" },
            { text: "Past Members", url: "https://wchsutah.org/wchs/members-past.php" },
        ],
    },
    { text: "Donations", url: "https://washcouthistory.org/donate" },
    {
        text: "Partners",
        dropdown: [
            { text: "Partner Societies", url: "https://wchsutah.org/index1.php" },
            { text: "Partner Museums", url: "https://wchsutah.org/index2.php" },
            { text: "Related Organizations", url: "https://wchsutah.org/related-organizations.php" },
            { text: "Sponsors", url: "https://wchsutah.org/wchs/sponsors.php" },
        ],
    },
];

const icon_links = [
    // { text: "Search", icon: fasSearch, url: null },
    { text: "Calendar", icon: fasCalendarDays, url: "/calendar.php" },
    { text: "Facebook", icon: fabFacebook, url: "https://www.facebook.com/WashingtonCountyHistoricalSociety" },
    { text: "Youtube", icon: fasYoutube, url: "https://www.youtube.com/channel/UCC0dSDjdpnRXzs6tZdDclrQ" },
    { text: "Work in Progress", icon: fasPersonDigging, url: "/miscellaneous/work-in-progress.php" },
];

// variables to store the data that will be fetched from the JSON
let news_text_items = [];
let news_photos_items = [];
let event_items = [];
let photo_items = [];

// Fetches data from local JSON to populate announcement area
const getCurrentNewsData = () => {
    fetch("liveAnnouncementData.json", {
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
    })
        .then(function (response) {
            return response.json();
        })
        .then(function (myJson) {
            if (myJson.news_text !== undefined) {
                news_text_items = myJson.news_text;
            }
            if (myJson.news_photos !== undefined) {
                news_photos_items = myJson.news_photos;
            }
            if (myJson.events !== undefined) {
                event_items = myJson.events;
            }
            if (myJson.photos !== undefined) {
                photo_items = myJson.photos;
            }
        });
};
getCurrentNewsData();

// populates the annoucnements sections if JSON has items and creates each item
function Current_News_Section() {
    if (news_text_items.length > 0 || news_photos_items.length > 0 || event_items.length > 0 || photo_items.length > 0) {
        return (
            <div id="news-area">
                <h3>Announcements</h3>
                <div id="news-items-area">
                    {announcement_settings['news_header'] && (news_text_items.length > 0 || news_photos_items.length > 0) && <h4 className="item-headers">News</h4>}
                    {news_photos_items.map((photos, photos_i) => {
                        return (
                            // Item div
                            <div className="photo-item" key={photos_i}>
                                {/* Link around the whole item to redirect to item page */}
                                {photos.url == "" && <img className="photos-image" src={local_or_server_image_checker(photos.image)} />}
                                {photos.url != "" && (
                                    <a className="photo-anchor" href={photos.url} target="_blank" rel="noreferrer">
                                        {/* typechecks the flyer key to see if it is a local image in the public folder or another picture linked for thumbnail */}
                                        <img className="photos-image" src={local_or_server_image_checker(photos.image)} />
                                    </a>
                                )}
                            </div>
                        );
                    })}
                    {news_text_items.map((news, news_i) => {
                        return (
                            // Item div
                            <div className="news-item" key={news_i}>
                                {/* Link around the whole item to redirect to item page */}
                                <a className="news-anchor" href={news.url} target="_blank" rel="noreferrer">
                                    <div className="news-text-container">
                                        <h5 className="news-text-header">{news.title}</h5>
                                        <p className="news-text-item">{news.text}</p>
                                    </div>
                                </a>
                            </div>
                        );
                    })}
                    {announcement_settings['event_header'] && event_items.length > 0 && <h4 className="item-headers">Events</h4>}
                    {event_items.map((events, events_i) => {
                        // console.log(events);
                        return (
                            // Item div
                            <div className="events-item" key={events_i}>
                                {/* Link around the whole item to redirect to item page */}
                                <a className="events-anchor" href={events.flyerURL} target="_blank" rel="noreferrer">
                                    {/* typechecks the flyer key to see if it is a local image in the public folder or another picture linked for thumbnail */}
                                    {/* {console.log(events.image)} */}
                                    {events.image !== "" && <img className="events-image" src={local_or_server_image_checker(events.image)} />}
                                    {events.image === "" && <div className="events-image" style={{ width: "5rem" }} />}
                                    <div className="events-details">
                                        <p className="events-header">{events.title}</p>
                                        <p className="events-date">{events.date}</p>
                                    </div>
                                </a>
                            </div>
                        );
                    })}
                    {announcement_settings['photo_header'] && photo_items.length > 0 && <h4 className="item-headers">Photos</h4>}
                    {/* {console.log(photo_items)} */}
                    {photo_items.map((photos, photos_i) => {
                        // console.log(photos);
                        return (
                            // Item div
                            <div className="photo-item" key={photos_i}>
                                {/* Link around the whole item to redirect to item page */}
                                <a className="photo-anchor" href={photos.url} target="_blank" rel="noreferrer">
                                    {/* typechecks the flyer key to see if it is a local image in the public folder or another picture linked for thumbnail */}
                                    <img className="photos-image" src={local_or_server_image_checker(photos.url)} />
                                </a>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    } else {
        return <div></div>;
    }
}

function local_or_server_image_checker(the_url) {
    if (undefined !== the_url) {
        // console.log(the_url['flyer']);
        const url_slice = the_url.slice(0, 5);
        // console.log(url_slice);
        if (url_slice === "https") {
            // console.log("External Link");
            return the_url;
        } else {
            // console.log("localFolder");
            return process.env.PUBLIC_URL + `${the_url}`;
        }
    }
}

// JSON stuff for Site Management
let current_year = 0;
let landing_random_hero = false;
let announcement_settings = {}
let image_delay = 1;

async function getSiteManagement () {
    const response = await fetch("liveSiteManagement.json", {
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
    });
    const the_data = await response.json();
    const year_data = the_data.copyright_year;
    const hero_data = the_data.landing_random_hero;
    current_year = year_data;
    landing_random_hero = hero_data;
    announcement_settings["news_header"] = the_data.news_header;
    announcement_settings["event_header"] = the_data.event_header;
    announcement_settings["photo_header"] = the_data.photo_header;
    image_delay = the_data.image_delay;

};
getSiteManagement();


class App extends React.Component {
    constructor(props) {
        super(props);
        const Z = this;
        // These are the images for the landing-page slideshow/carousel
        // eslint-disable-next-line
        const images = ["./img/01-McArthur Lot.jpg",
            "./img/02-Foster Farm Home.jpg",
            "./img/03-Fort Harmony.jpg",
            "./img/04-Grafton Aerial.jpg",
            "./img/05-leeds tithing house.jpg",
            "./img/06-larson-cabin3.jpg",
            "./img/07-rockville bridge.jpg",
            "./img/08-fishing at enterprise dam 1912.jpg",
            "./img/09-st-johns-catholic-church1.jpg",
            "./img/10-st-george-ccc-camp.jpg",
            "./img/11-pine-valley-chapel2.jpg",
            "./img/12-whitehead-merchandise-building1.jpg",
            "./img/13-opera-house4.jpg",
            "./img/14-old-county-courthouse3.jpg",
            "./img/15-wchs-01089.jpg",
            "./img/16-wchs-01130.jpg",
            "./img/17-washington-tunnel3.jpg",
            "./img/18-jacob-hamblin-home1.jpg",
            "./img/19-st-george-2nd-post-office1.jpg",
            "./img/20-warm-springs0.jpg",
            "./img/21-washington-church-school2.jpg",
            "./img/22-wchs-00111.jpg",
            "./img/23-wchs-00355.jpg",
            "./img/24-wchs-01071.jpg",
            "./img/24-wchs-01071.jpg",
            "./img/25-wchs-02343.jpg",
            "./img/26-wchs-02483.jpg",
            "./img/27-wchs-04066.jpg",
            "./img/28-1937 shem dam.jpg",
            "./img/29-cotton factory people judd in wagon wchs-02392.jpeg",
            "./img/30-dolph andrus maxwell hurricane road.jpg",
            "./img/31-judd's store.jpg",
            "./img/32-peaches dorothea lange.jpg",
            "./img/33-people on yy-completed enterprise dam 1910.jpg",
            "./img/34-washington fields dam spillway work wchs-01326.jpg"
        ];

        // passing into state feature_image_slide_delay which changes the length of delay for landing page slideshow JPL
        // also passing in list of image locations/links and the counter for slideshow on landing-page JPL

        Z.state = {
            intro: false,
            mobile_menu_open_01: false,
            mobile_menu_open_02: false,
            feature_image_slide_delay: image_delay * 1000,
            images,
            currentImg: 0,
        };

        Z.vars = {
            _load: false,
            _init: false,
            load_and_ready_done: false,
        };
    }
    componentDidMount() {
        const Z = this;
        // Z.__load_listener();
        Z.intro(); // temp fix for __load_listener not triggering sometimes
        Z.init();
        // console.log("something " + landing_random_hero);
        this.interval = setInterval(() => this.changeBackgroundImage(), this.state["feature_image_slide_delay"]); // Sets the interval for changeBackground JPL
    }
    // Clears the interval when unmounted JPL
    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }
    // function that creates slide show for featured images on the landing-page JPL
    changeBackgroundImage() {
        let newCurrentImage = 0;
        const { images, currentImg } = this.state;
        const numberOfImages = images.length;
        if (currentImg !== numberOfImages - 1) {
            newCurrentImage = currentImg + 1;
        } 
        // console.log(landing_random_hero);
        // if (landing_random_hero) {
        //     console.log("random");
        //     clearInterval(this.interval);
        //     this.interval = setInterval(() => this.random_hero_img(), this.state["feature_image_slide_delay"]); // Sets the interval for changeBackground JPL
        //     this.random_hero_img();
        // } else {
            this.setState({ currentImg: newCurrentImage });
        // }
    }
    // Load random image from list on page load / was a test JPL
    random_hero_img () {
        const { images, currentImg } = this.state;
        let random = Math.floor(Math.random() * images.length);
        // console.log(images[random])
        this.setState({ currentImg: random });
    };
    set_random_hero_img () {
        clearInterval(this.interval);
        this.interval = setInterval(() => this.random_hero_img(), this.state["feature_image_slide_delay"]); // Sets the interval for changeBackground JPL
        this.random_hero_img();
    };
    // __load_listener = () => {
    //     const Z = this;
    //     if (window.is_loaded == true && window.is_ready) Z.on_load_and_ready();
    //     else {
    //         window.on_load_and_ready = Z.on_load_and_ready;
    //     }
    //     setTimeout(() => {
    //         Z.on_load_and_ready();
    //     }, 1000 * 2000);
    // };
    // on_load_and_ready = () => {
    //     // Wait for images to load, etc.
    //     const Z = this;
    //     if (window.is_loaded != true && window.is_ready != true) return false;
    //     if (Z.vars.load_and_ready_done) return false;
    //     Z.vars.load_and_ready_done = true;
    //     //
    //     if (Z.vars._load) return false; // ensures that we execute only once
    //     Z.vars._load = true;
    //     //
    //     // console.log("load!");
    //
    //     Z.intro();
    // };

    init = () => {
        const Z = this;
        if (Z.vars._init) return false; // ensures that we execute only once
        Z.vars._init = true;
        //
        // ...
    };

    intro = () => {
        const Z = this;

        setTimeout(() => {
            Z.setState({ intro: true });
        }, 1000 * 0.64);
    };

    toggle_mobile_menu = (event) => {
        const Z = this;

        const new_menu_visible = Z.state.mobile_menu_open_02 == false ? true : false;
        // console.log("new_menu_visible", new_menu_visible);

        if (new_menu_visible == true) {
            // collapse
            Z.setState({ mobile_menu_open_01: true }, () => {
                setTimeout(() => {
                    Z.setState({ mobile_menu_open_02: true });
                }, 1000 * 0.0);
            });
        } else {
            // expand
            Z.setState({ mobile_menu_open_02: false }, () => {
                setTimeout(() => {
                    Z.setState({ mobile_menu_open_01: false });
                }, 1000 * 0.21); // make sure this duration matches the transition-duration in .mobile-menu (CSS)
            });
        }
    };
    
    render() {
        const Z = this;
        const { images, currentImg } = Z.state;
        // console.log("before" + image_delay);
        // console.log("render" + Z.state.feature_image_slide_delay);
        if (image_delay * 1000 !== Z.state.feature_image_slide_delay) {
            if (landing_random_hero) {
                clearInterval(this.interval);
                this.interval = setInterval(() => this.random_hero_img(), image_delay * 1000); // Sets the interval for changeBackground JPL
                // Z.setState({ feature_image_slide_delay: image_delay * 1000 });
                Z.state.feature_image_slide_delay = image_delay * 1000
            
            } else {
                clearInterval(this.interval);
                this.interval = setInterval(() => this.changeBackgroundImage(), image_delay * 1000); // Sets the interval for changeBackground JPL
                Z.state.feature_image_slide_delay = image_delay * 1000

            }
            
        }
        if (current_year === 0 && image_delay === 1) { 
            return (<div></div>)
        } else {
            return (
                <div className={`home ${Z.state.intro ? "_visible" : ""}`}>
                    {/* <Helmet>
                        <meta charSet="utf-8" />
                        <title>Washington County, Utah - Historical Society</title>
                        <link rel="preload" href={require(`./font/Open_Sans/OpenSans-VariableFont_wdth,wght.ttf`)} as="font" crossOrigin="" />
                        <link rel="preload" href={require(`./font/Open_Sans/OpenSans-Italic-VariableFont_wdth,wght.ttf`)} as="font" crossOrigin="" />
                        <link rel="preload" href={require(`./font/Lora/Lora-VariableFont_wght.ttf`)} as="font" crossOrigin="" />
                        <link rel="preload" href={require(`./font/Lora/Lora-Italic-VariableFont_wght.ttf`)} as="font" crossOrigin="" />
                    </Helmet> */}

                    <div className="mobile-menu-button-wrap">
                        <div className="mobile-menu-button" onClick={Z.toggle_mobile_menu}>
                            <div className={`hamburger ${Z.state.mobile_menu_open_02 ? "open" : ""}`}>
                                <div className="hamburger-line line-01"></div>
                                <div className="hamburger-line line-02"></div>
                                <div className="hamburger-line line-03"></div>
                            </div>
                        </div>
                    </div>

                    <div className={`mobile-menu ${Z.state.mobile_menu_open_01 ? "_exists" : ""} ${Z.state.mobile_menu_open_02 ? "_visible" : ""}`}>
                        <div className="mobile-menu-link-set">
                            {header_links.map((link, link_i) => {
                                if (link.dropdown) {
                                    return (
                                        <div className="mobile-menu-dropdown-set" key={`${link_i}`}>
                                            {link.dropdown.map((dd, dd_i) => {
                                                // console.log("DROPDOWN:", dd);
                                                return (
                                                    <a className="mobile-menu-link" key={`${dd_i}`} href={dd.url || "/"}>
                                                        <span className="mobile-menu-link-text">{dd.text}</span>
                                                    </a>
                                                );
                                            })}
                                        </div>
                                    );
                                } else {
                                    return (
                                        <a className="mobile-menu-link" key={`${link_i}`} href={link.url || "/"}>
                                            <span className="mobile-menu-link-text">{link.text}</span>
                                        </a>
                                    );
                                }
                            })}
                        </div>
                    </div>

                    <header className="header">
                        <div className="header-main">
                            <div className="logotype">
                                <div className="line-01">Washington County Historical Society</div>
                                <div className="line-02">Washington County, Utah</div>
                            </div>
                        </div>
                        <div className="header-links-row">
                            <div className="header-links-set">
                                {header_links.map((link, link_i) => {
                                    if (link.dropdown) {
                                        return (
                                            <div className="header-link-item" key={`${link_i}`} href={link.url}>
                                                <span className="header-link-text">{link.text}</span>
                                                <div className="header-link-dropdown">
                                                    {link.dropdown.map((dd, dd_i) => {
                                                        return (
                                                            <a className="header-link-item header-link-dropdown-item" key={`${dd_i}`} href={dd.url || "/"}>
                                                                <span className="header-link-text">{dd.text}</span>
                                                            </a>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        );
                                    } else {
                                        return (
                                            <a className="header-link-item" key={`${link_i}`} href={link.url || "/"}>
                                                <span className="header-link-text">{link.text}</span>
                                            </a>
                                        );
                                    }
                                })}
                            </div>
                        </div>
                    </header>

                    <div className="featured">
                        <div className={`featured-image ${Z.state.intro ? "_visible" : ""}`}>
                            <img className="featured-image-inner" src={require(`${images[currentImg]}`)} />
                        </div>
                        {/* <div className={`featured-image ${Z.state.intro ? "_visible" : ""}`} style={{ backgroundImage: `url(${require("./img/McArthur-Lot.jpg")})` }}></div> */}
                    </div>

                    <div className="search-wrap">
                        <Search_Box />
                    </div>

                    <div className="icon-set">
                        {icon_links.map((icon, icon_i) => {
                            return (
                                <a className="icon" key={icon_i} href={icon.url}>
                                    <div className="icon-icon">
                                        <FAIcon icon={icon.icon} />
                                    </div>
                                    <span className="icon-title">{icon.text}</span>
                                </a>
                            );
                        })}
                    </div>

                    {/* <Current_News_Area props={news_items} /> */}
                    <Current_News_Section />

                    <div className="footer-divider"></div>

                    <div className="footer">
                        <div className="footer-row">
                            <span className="footer-text">Copyright © 2011-{current_year} &nbsp; Washington County Historical Society</span>
                        </div>
                        <div className="footer-row">
                            <a className="footer-link" href="/site-map.php">
                                Site Map
                            </a>
                            &nbsp; &nbsp; - &nbsp; &nbsp;
                            <a className="footer-link" href="mailto:info@wchsutah.org">
                                info@wchsutah.org
                            </a>
                        </div>
                        <div className="footer-row">
                            <a className="footer-link" href="/privacy-policy.php" rel="nofollow noopener">
                                Privacy Policy
                            </a>
                            &nbsp; &nbsp; - &nbsp; &nbsp;
                            <a className="footer-link" href="/terms-of-service.php" rel="nofollow noopener">
                                Terms of Service
                            </a>
                            &nbsp; &nbsp; - &nbsp; &nbsp;
                            <a className="footer-link" href="/cookie-consent.php" rel="nofollow noopener">
                                Cookie Consent Notice
                            </a>
                        </div>
                        <div className="footer-row">
                            <br />
                        </div>
                    </div>
                </div>
            );
        }
    }
}

//
//

export default App;
